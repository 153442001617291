.button {
    padding: 9px 13px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    font-size: 12px;
    background-color: white;
    margin: 8px;
    

}

.button:hover {
    background-color: #749cc5;
    color: white;
    border: solid 1px #749cc5;
}

.buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotBtn {
    border: 1px solid #749cc5;
    padding: 5px 30px;
    border-radius: 50px;
    background-color: white;
    color: #749cc5;;
    cursor: pointer;
    font-size: 15px;
    outline: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.forgotBtn:hover {
    background-color:#749cc5;
    color: white;
}